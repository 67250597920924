import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// render - login
const SendCode = Loadable(lazy(() => import('./../pages/auth/SendCode')));
const AuthLogin = Loadable(lazy(() => import('./../pages/auth/Login')));
const Register = Loadable(lazy(() => import('./../pages/auth/Register')));
const VerificationCode = Loadable(
  lazy(() => import('./../pages/auth/VerificationCode'))
);

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = [
  {
    path: '/login',
    element: <MinimalLayout />,
    children: [
      {
        path: '/login',
        element: <AuthLogin />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'send_code',
        element: <SendCode />
      },
      {
        path: 'verify_code',
        element: <VerificationCode />
      }
    ]
  }
];

export default LoginRoutes;
