const textEN = {
  french: 'french',
  english: 'english',
  login: 'Log in',
  email: 'Email',
  user: 'User',
  password: 'Password',
  typeMail: 'Enter email and password to login',
  rememberMe: 'Remember me',
  forgottenPassword: 'Forgot password',
  authenticate: 'Authenticate',
  dataLoading: 'Data Loading',
  dashboard: 'Dashboard',
  see: 'View',
  admin: 'Administrator',
  results: 'Results',
  next: 'Next',
  previous: 'Previous',
  save: 'Save',
  profil: 'Profile',
  settings: 'Settings',
  updateAccount: 'Update Account',
  viewProfile: 'View Profile',
  logOut: 'Log Out',
  help: 'Help',
  names: 'Name',
  phoneNum: 'Telephone Number',
  plan: 'Location Plan',
  cancel: 'Cancel',
  all: 'All',
  statut: 'Status',
  loading: 'Loading...',
  new_password: 'New password',
  confirm_password: 'Confirm new password',
  valider: 'Validate',
  // other
  driver_name: 'Driver Name',
  driver_ID: ' Driver ID',
  contact: 'contact',
  registrationDate: 'Registration Date',
  deliveries: 'Deliveries',
  payOut: 'pay out',
  username: 'username',
  filter: 'filter',
  report_statistics: 'Report & Statistics',
  feedback: 'feedback',
  subscription: 'subscription',
  restaurant_name: 'Restaurant name',
  location: 'location',
  last_renewal: 'last renewal',
  expiry_date: 'Expiry Date',
  view: 'View',
  active: 'active',
  inactive: 'inactive',
  new: 'New',
  app_users: 'App users',
  foodMenu: 'Food Menu',
  staff: 'Staff',
  profile: 'Profile',
  reviews: 'Reviews',
  totalsMenus: 'total Menu',
  totalsRiders: 'total Riders',
  ongoingDeliveries: 'Deliveries',
  completeDeliveries: 'complete deliveries',
  total_deliveries: 'Total Deliveries',
  all_restaurants: 'All Restaurant',
  restaurant_detail: 'Restaurant Detail',
  MostOrderedMeals: 'Most Ordered Meals',
  last_number_days: 'last #number days',
  starters: 'starters',
  main_courses: 'Main courses',
  no_items_found: 'no items found',
  items: 'items',
  description: 'Description',
  available: 'Available',
  unit_price: 'unit price',
  last_updated: 'last updated',
  staff_name: 'Staff name',
  position: 'position',
  access: 'access',
  date_added: 'date added',
  closed: 'closed',
  companyAvailabilities: ' company Availabilities',
  companyInfos: 'company Infos',
  paymentMethod: 'Payment Method',
  security: 'Security',
  companyType: ' company Type',
  email_address: 'email address',
  address_line: '  address line',
  owner_name: 'owner name',
  company_contact: 'company contact',
  open_time: 'open time',
  define_way_for_client_to_pay: ' define way for client to pay',
  password_access: 'password , access',
  credit_card: 'credit card',
  mobile_money: 'mobile money',
  other_payment_method: 'other payment method',
  your_rating: 'your rating',
  overall_rating: 'overall rating',
  rating_chart: 'rating chart',
  customer_reviews: 'customer reviews',
  spent: 'spent',
  drivers: 'drivers',
  orders: 'orders',
  total_sales: 'total sales',
  global_total: 'global total',
  total_orders: 'total orders',
  total_preparing: 'total preparing',
  total_successful: 'total successfull',
  total_cancelled: 'total cancelled',
  total_services_fee: 'Total services fee',
  total_ongoing: 'total ongoing',
  top_customers: 'top Customers',
  top_restaurants: 'top Restaurants',
  top_drivers: 'top Drivers',
  sales: 'sales',
  earnings: 'earnings',
  add_content: 'add content',
  content_desc: 'This content will appear on carousel',
  recommended_meals: 'Recommended Meals',
  add_meal: 'Add Meals',
  no_order_today: 'No order today',
  notification_image: 'notification image',
  click_to_upload: 'click to upload',
  drag_desc: 'or drag and drop',
  png_required: 'PNG, JPG | Required size',
  size_desc: '350x132px',
  legal_text: 'legal text',
  content_title: 'content title',
  content_text: 'content text',
  no_order_to_day: 'no order',
  total_transaction: 'total transaction',
  all_driver: 'Livreurs',
  last_3_days: 'last 3 days',
  totalMenus: 'total menu',
  ongoing_deliveries: ' ongoing deliveries',
  completed_deliveries: 'completed deliveries',
  lastUpdated: 'last update',
  customers_reviews: 'Customers reviews',
  no_reviews: 'no reviews',
  no_staff: 'no staff',
  all_restaurant: 'all restaurant',
  restaurant_details: 'restaurant details',
  new_rider: ' New rider',
  firstname: 'firstname',
  lastname: 'lastname',
  driver_desc:
    ' The Driver will access the Delivery app with this number. Make sure its the right number.',
  restaurant_desc:
    "The restaurant will access the administration with this number. Make sure it's the right number.",
  transport_type: 'transport type',
  number_plate: 'number plate',
  create_driver_desc:
    'Create a new driver that will have access to the system.',
  statistic: 'statistics',
  order_history: 'order history',
  total_earnings: 'total earnings',
  requested_on: ' requested on',
  total_delivered: 'total delivered',
  total_coast: 'total cost',
  order_id: 'Order ID',
  customer: 'customer',
  delivery_address: 'delivery address',
  order_detail: 'order detail',
  delivery_fee: 'delivery fee',
  estimated_time: 'estimated time',
  no_order: 'no orders',
  search: 'search',
  registered_on: 'registered on',
  driver: 'driver',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
  NEW: 'new', // hen we have just published the order
  READY: 'prepared', // when food is being prepared
  PREPARING: 'preparing', // when food has been prepared and waiting driver
  ON_ROUTE: 'on route', // when the driver is en route for delivery
  rider_cancelled: 'rider cancelled',
  device_preview: 'device preview',
  device_preview_desc:
    'This preview provides a general idea of how your message will appear on a mobile device.',
  remove: 'remove',
  edit_content: 'edit content',
  no_driver: 'no driver',
  no_app_users: 'no app users',
  add_restaurant_desc:
    'Create a new restaurant that will have access to the system.',
  restau_name: 'nom du restaurant',
  add_restaurant: 'Add restaurant',
  business_location: 'business location',
  phone_number: 'phone number',
  no_meals_found: 'No meals found',
  to_day: 'to day',
  hot_deals: 'hot deals',
  ordered_meals: 'ordered meals',
  earning: 'earning',
  earned: 'earned',
  active_orders: 'commandes actives',
  user_details: 'user detail',
  payment_options: 'payment options',
  no_payment_options: 'no payment options',
  last_order: 'last order',
  join_on: 'join on',
  opened: 'opened',
  campaign: 'campaign',
  total_spent: 'total spent',
  customers: 'customers',
  id_no: 'ID No',
  name: 'name',

  app_settings: 'app settings',
  category_tags: 'category tags',
  category_tags_desc: 'Meals tag for better search',
  hot_deals_and_new_meals: 'hot deals and new meals',
  hot_deals_and_new_meals_desc: 'Time for this offers',
  rating_word: 'rating word',
  rating_words: 'rating words',
  rating_words_desc: 'Define the words for ratings',
  reasons: 'reasons',
  reasons_desc: 'For restaurant canceling an order',
  menu_categories: 'menu categories',
  menu_categories_desc: 'Define catégories',
  selected_tags: 'selected tags',
  last_update: 'last update',
  thumbnail: 'thumbnail',
  tag_name: 'tag name',
  new_category_tags: 'new category tags',
  new_category_tags_desc: 'Add tags and thumbnail',
  categories: 'categories',
  add: 'add',
  delete: 'delete',
  hot_deals_and_new_meals_duration: 'hot deals and new meals duration',
  hot_deals_max_duration: 'hot deals  max duration',
  new_meal: 'new meal',
  days: 'days',
  week: 'week',
  month: 'month',
  update: 'update',
  word: 'word',
  new_rating_word: 'new rating word',
  new_rating_word_desc: 'Add new rating word',
  new_word: 'new word',
  category: 'category',
  sub_category: 'sub category',
  new_menu_category: 'New menu category',
  edit_menu_category: 'update menu category',
  edit_menu_category_desc:
    'modify info and click on save to update menu category',
  new_menu_category_desc: 'Enter the menu name and sub-categories',
  category_group: 'category group',
  click_to_add: 'click to add',
  number: 'number',
  edit_category_tag: 'edit category tag',
  edit_category_tag_desc: 'modify info and click on save to update',
  block_driver: 'block driver',
  block_driver_desc: 'Are you sure you want to block this driver?',
  yes_block: 'yes , block'
};
export default textEN;
