// types
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '.';

export const initialState = {
  user: null,
  temporal: {},
  initialMinutes: 180,
  idToken: null
} as any;

// ==============================|| SLICE - MENU ||============================== //

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth(state, { payload }) {
      state.user = {};
      state.user.token = payload?.token;
      state.user = {
        ...state.user,
        ...payload?.manager,
        companies: payload?.companies,
        id: payload.manager._id
      };
    },
    updateProfile(state, { payload }) {
      state.user = {
        ...state.user,
        ...payload
      };
    },
    addTemp(state, { payload }) {
      state.temporal = {
        ...state.temporal,
        ...payload
      };
    },
    updatePmeInfo(state, { payload }) {
      const comp = state.user.companies || [];
      const newComp = comp.map((el: any) =>
        el?._id === payload?._id ? { ...el, ...payload } : el
      );
      state.user.companies = newComp;
      return state;
    },
    addPmeInfo(state, { payload }) {
      state.user.companies.push({ ...payload });
      return state;
    },
    deletePme(state, { payload }) {
      state.user.companies = state.user.companies.filter(
        (el: any) => el._id !== payload
      );
      return state;
    },

    addUuid: (state, { payload }) => {
      state.user.uuid = payload;
    },
    refreshToken: (state, { payload }) => {
      // console.log('on refresh token slice', payload);
      state.user.token = payload;
    },
    resetInitialMinutes(state, { payload }) {
      state.initialMinutes = payload;
      return state;
    }
  }
});

export default authSlice.reducer;

export const {
  initAuth,
  addUuid,
  addTemp,
  refreshToken,
  updatePmeInfo,
  addPmeInfo,
  deletePme,
  updateProfile,
  resetInitialMinutes
} = authSlice.actions;

export const onLogout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch({ type: 'USER_LOGGED_OUT' });
  } catch (error) {
    console.log('logout error', error);
  }
};
