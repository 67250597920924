// types
import { createSlice } from '@reduxjs/toolkit';
import Program from 'models/program.model';
// import { AppDispatch } from '.';

export const initialState = { data: {} } as any;

// ==============================|| SLICE - MENU ||============================== //

const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    initProgram(state, { payload }) {
      const items: Record<string, any> = {};
      payload?.forEach((item: Program) => {
        items[item._id + ''] = item;
      });
      state.data = items;
    }
  }
});

export default programSlice.reducer;

export const { initProgram } = programSlice.actions;
