// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/',
  fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export const baseUrl = 'https://api.sipa-togo.com/api/v1/';

export default config;
export const drawerWidth = 280;
export const drawerClosedWidth = 70;
export const paperBoxShadow = 'rgba(145, 158, 171, 0.16) 0px 4px 8px';
export const appTitle = 'SIPA-PMEs';
