import Program from 'models/program.model';
import api from '.';
import RequestEvaluation from 'models/request_evaluation.model';
import { initProgram } from 'store/program';

export const programApi = api.injectEndpoints({
  endpoints: (build) => ({
    evaluationPme: build.mutation<any, RequestEvaluation>({
      query: (credentials: RequestEvaluation) => ({
        url: 'request-evaluation',
        method: 'POST',
        body: credentials
      })
    }),
    allProgram: build.query<Program, void>({
      query: () => ({ url: '/programs' }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;

            console.log({ data });
            dispatch(initProgram(data));
          })
          ?.catch((err) => {
            console.warn('get programs error', err);
          });
      }
    })
  })
});

export const {
  useEvaluationPmeMutation: useEvaluatePmeAsync,
  useAllProgramQuery: useAllProgramAsync
} = programApi;

export default programApi;
