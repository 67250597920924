import {
  Avatar,
  Button,
  Box,
  Popper,
  ClickAwayListener,
  Paper,
  Typography,
  Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DropdownIcon from 'assets/icons/DropdownIcon';
import LogoutIcon from 'assets/icons/LogoutIcon';
import Transitions from 'components/Transitions';
import useAuth from 'hook/useAuth';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { onLogout } from 'store/auth';

export default function ProfileSection() {
  const theme: any = useTheme();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const anchorRef: any = useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const log = () => dispatch(onLogout() as any);

  return (
    <Box>
      <Button
        size="small"
        startIcon={<Avatar src="url" sx={{ height: 32, width: 32 }} />}
        endIcon={<DropdownIcon fill="#FFF" />}
        sx={{ color: '#FFF' }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {user?.firstname + ' ' + user?.lastname}
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow:
                    'linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)) rgb(255, 255, 255)',
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box p={3}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={log}
                      spacing={2}
                    >
                      <LogoutIcon />
                      <Typography>Se deconnecter</Typography>
                    </Stack>
                  </Box>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}
