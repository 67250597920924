import { useEffect } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { refreshToken } from 'store/auth';
import { useDispatch } from 'react-redux';
import { useAllProgramAsync } from 'api/programApi';
import { useRequestAsync } from 'api/requestApi';
import { useConfigAsync, useRefreshTokenAsync } from 'api/authApi';

export default function useInit() {
  const dispatch = useDispatch();
  const { token, _id } = useSelector(
    (state: RootState) => state.auth?.user || {}
  );
  const { isLoading } = useAllProgramAsync();
  const { isLoading: loadingConfig } = useConfigAsync();
  const [getRequest, { isLoading: loadingRequest }] = useRequestAsync();
  const [refresh] = useRefreshTokenAsync();

  useEffect(() => {
    const refresher = setInterval(() => {
      refresh(token)
        .unwrap()
        .then((responseToken: any) => {
          if (responseToken.data.token) {
            dispatch(refreshToken(responseToken.data.token));
            console.log('refreshing token', responseToken.data.token);
          } else {
            console.log('err when refresh token', responseToken?.error);
          }
        })
        .catch((err) => {
          console.log('err when refresh token', err);
        });
    }, 1000 * 60 * 30);

    return () => clearInterval(refresher);
  }, [dispatch, refresh, token]);

  useEffect(() => {
    if (_id) {
      getRequest({ managerID: _id });
    }
  }, [_id, getRequest]);

  return { loading: isLoading || loadingConfig || loadingRequest };
}
