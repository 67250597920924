import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import { Navigate } from 'react-router-dom';
// import EvaluationIcon from 'assets/icons/drawer/EvaluationIcon';
import AllProgramIcon from 'assets/icons/drawer/AllProgramIcon';
import ProgramIcon from 'assets/icons/drawer/ProgramIcon';
import ProfileIcon from 'assets/icons/drawer/ProfileIcon';
import WaIcon from 'assets/icons/drawer/WaIcon';
import NotificationIcon from 'assets/icons/NotificationIcon';
import PhoneIcon from 'assets/icons/drawer/PhoneIcon';

const Page404 = Loadable(lazy(() => import('../pages/404/NotFound')));
const Evaluation = Loadable(lazy(() => import('../pages/evaluation/index')));
const AllProgram = Loadable(lazy(() => import('../pages/program/AllProgram')));
const Program = Loadable(lazy(() => import('../pages/program/Program')));
const ManagerPrograms = Loadable(
  lazy(() => import('../pages/program/ManagerProgram'))
);
const ManagerProgramDetail = Loadable(
  lazy(() => import('../pages/program/ManagerProgramDetail'))
);
const EvaluationPage = Loadable(
  lazy(() => import('../pages/evaluation/Evaluate'))
);
const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));
const CompanyForm = Loadable(
  lazy(() => import('../pages/profile/CompanyForm'))
);
const ProfileForm = Loadable(
  lazy(() => import('../pages/profile/ProfileForm'))
);
const PasswordForm = Loadable(
  lazy(() => import('../pages/profile/PasswordForm'))
);

export const main_routes_items: any[] = [
  // {
  //   id: 1,
  //   path: '/evaluation',
  //   name: 'evaluation',
  //   title: 'evaluation',
  //   icon: EvaluationIcon
  // },
  {
    id: 2,
    path: '/all_program',
    name: 'all_program',
    title: 'Tous les programmes',
    icon: AllProgramIcon
  },
  {
    id: 3,
    path: '/programs',
    name: 'programs',
    title: 'vos programmes',
    icon: ProgramIcon
  },
  {
    id: 4,
    path: '/profile',
    name: 'profile',
    title: "profil de l'entreprise",
    icon: ProfileIcon
  }
];

export const faqLink = {
  id: 5,
  path: '#',
  name: 'faq',
  title: 'FAQs',
  icon: WaIcon
};

export const contactLink = {
  id: 7,
  path: '#',
  name: 'contact',
  title: 'Contactez-nous',
  icon: PhoneIcon
};

export const notifLink = {
  id: 6,
  path: '#',
  name: 'notifications',
  title: 'notifications',
  icon: () => <NotificationIcon fill="#333333" />
};

const main_routes = [
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <Page404 />,
    children: [
      { path: '/', element: <Navigate to="/all_program" />, index: true },
      { path: '/evaluation', element: <Evaluation /> },
      { path: '/evaluation/form/', element: <EvaluationPage /> },
      { path: '/evaluation/form/:programID', element: <EvaluationPage /> },
      { path: '/all_program', element: <AllProgram /> },
      { path: '/all_program/:programID', element: <Program /> },
      { path: '/programs', element: <ManagerPrograms /> },
      { path: '/programs/:programID', element: <ManagerProgramDetail /> },
      { path: '/profile', element: <Profile /> },
      { path: '/profile/companyForm', element: <CompanyForm /> },
      { path: '/profile/companyForm/:pmeID', element: <CompanyForm /> },
      { path: '/profile/profileForm', element: <ProfileForm /> },
      { path: '/profile/passord_form', element: <PasswordForm /> }
    ]
  }
];

export default main_routes;
