import Program from 'models/program.model';
import api from '.';
import RequestEvaluation from 'models/request_evaluation.model';
import { listRequest } from 'store/request';

export const requestApi = api.injectEndpoints({
  endpoints: (build) => ({
    evaluationPme: build.mutation<any, RequestEvaluation>({
      query: (credentials: RequestEvaluation) => ({
        url: 'request-evaluation',
        method: 'POST',
        body: credentials
      })
    }),
    request: build.mutation<Program, any>({
      query: (criteria) => ({
        url: '/request-evaluation/filter',
        body: criteria,
        method: 'POST'
      }),
      onQueryStarted: (arg, { queryFulfilled, dispatch }) => {
        queryFulfilled
          .then(({ data: res }: any) => {
            const data = res.data;
            // console.log('request of current user', res.data);
            dispatch(listRequest(data));
          })
          ?.catch((err) => {
            console.warn('get company error', err);
          });
      }
    })
  })
});

export const {
  useEvaluationPmeMutation: useEvaluatePmeAsync,
  useRequestMutation: useRequestAsync
} = requestApi;

export default requestApi;
