import { CircularProgress, Stack, SxProps } from '@mui/material';

export default function CircularLoader({
  containerStyle,
  loading
}: {
  containerStyle?: SxProps;
  loading?: boolean;
}) {
  if (!loading) {
    return <></>;
  }
  return (
    <Stack
      width={1}
      height={1}
      minHeight={600}
      alignItems="center"
      justifyContent="center"
      sx={{ ...containerStyle }}
    >
      <CircularProgress />
    </Stack>
  );
}
