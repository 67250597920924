/* eslint-disable no-unused-vars */
import {
  FetchArgs,
  createApi,
  fetchBaseQuery,
  retry
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { onLogout } from 'store/auth';
import { baseUrl } from 'config';

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  // baseUrl: 'http://localhost:8080/',
  // baseUrl: 'http://192.168.0.121:3000/api/v1/',
  baseUrl: baseUrl,
  prepareHeaders: (headers, { endpoint, getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState)?.auth?.user?.token;
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      headers.set('ngrok-skip-browser-warning', 'sypa-key');
    }
    return headers;
  }
});

// old const baseQueryWithRetry = retry(baseQuery , {maxRetries:1})

const baseQueryWithRetry = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    // check if token expired

    if (result?.error?.status === 401) {
      // console.log('token expired');
      api.dispatch(onLogout());
    }

    return result;
  },
  { maxRetries: 0 }
);

const api = createApi({
  reducerPath: 'Api',
  baseQuery: baseQueryWithRetry,
  endpoints: () => ({})
});

export default api;
